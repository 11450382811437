
.footer {
    width: 100%;
    background-color: #000;
    color: #eee;
    padding: 1.2rem 1rem;

    clear: both; /* Agregado: Evita superposición con secciones anteriores */
}

.footer .container {
    max-width: 1240px;
    margin: auto;
    text-align: center;
}

.footer ul {
    display: flex;
    justify-content: center;
}

.footer li {
    padding: 1rem;
}

.footer li a {
    color: #fff;
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.footer .redes {
    margin-bottom: 1rem;
}

.bottom {
    text-align: center;
    padding-top: 1rem;
}

.line {
    margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
    /* Ajustes para resoluciones de pantalla hasta 768px */
    .footer .container {
        padding: 0 1rem;
    }

    .footer ul {
        flex-wrap: wrap;
    }

    .footer li {
        flex: 0 0 50%;
        padding: 0.5rem;
    }
}

@media screen and (max-width: 576px) {
    /* Ajustes para resoluciones de pantalla hasta 576px */
    .footer .container {
        padding: 0 0.5rem;
    }

    .footer li {
        flex: 0 0 100%;
        padding: 0.5rem;
    }
}
