.header {
    position: fixed;
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: rgba(0,0,0,.9);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1.5rem;
}

.header .nav-menu a {
    color: #ffffff;
    font-size: 1em;
}

.header .navbar p {
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 600;
    color: #ffffff;
}

.header .navbar img {
    width: 50px;
    height: auto;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}

.header .nav-item a:hover {
    padding-bottom: 8px;
    border-bottom: 3px solid var(--secondary-color); 
}

.hamburger {
    display: none;
}

@media screen and (max-width: 768px) {
    .header {
        max-width: 100%;
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: 100%;
        top: 80px;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    .header .navbar img {
        width: 66px;
    }
}

