.Curriculum {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    background-color: #830b0b;
}

.Curriculum .contenido {
    text-align: justify;
    color: white;
    width: 100%;
    border-radius: 10px;
    margin: 4% 0;
}

.Curriculum h1 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin-bottom: 3%;
    text-shadow: 1px 2px #000;
}

.Curriculum p {
    font-family: Arial, Helvetica, sans-serif;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
    .Curriculum {
        padding: 2%;
    }

    .Curriculum h1 {
        font-size: 24px;
        margin-top:2em;
    }

    .Curriculum .btn {
        font-size: 14px;
    }
}
