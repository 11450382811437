section {
    background-color: #8d0505;
    height: 50vw;
}

.ubicacion h1 {
    text-align: center;
    color: #fff;
    font-size: 3rem;
    width: 100%;
    margin-bottom: 2%;
    padding: 1%;
    position: relative;
}

.Ubicacion {
    background-color: #000000;
}

.googleMaps {
    background-color: #000000;
}

.body {
    position: absolute;
    background-color: #000000;
    border: none;
    width: 20rem;
    height: 31vw;
    margin-left: 75%;
}

@media screen and (max-width: 1200px) {
    section{
        height: 80vh;
    }

    .ubicacion h1 {
        font-size: 2.5rem;
        margin-bottom: 2%;
        padding: 0.75%;
    }

    .body {
        margin-left: 70%;
    }
}

@media screen and (max-width: 992px) {

    section{
        height: 90vh;
    }

    .ubicacion h1 {
        font-size: 2rem;
        margin-bottom: 1%;
        padding: 0.5%;
    }

    .Ubicacion,
    .googleMaps,
    .body {
        margin-left: 0;
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    section {
        height: 90vh;
    }

    .ubicacion h1 {
        font-size: 2rem;
        margin-bottom: 1%;
        padding: 0.5%;
    }

    .Ubicacion,
    .googleMaps,
    .body {
        margin-left: 0;
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 576px) {
    section {
        height: 90vh;
    }

    .ubicacion h1 {
        font-size: 1.5rem;
        margin-bottom: 0.5%;
        padding: 0.25%;
    }

    .body {
        margin-left: 50%;
    }
}
