.galeria {
    max-width: 124rem;
    padding: 3rem 1rem;
    margin: 0 auto;
}

.galeria .heading {
    padding: 1rem 0;
    font-size: 2.2rem;;
    text-align: center;
}

.galeria .swiper_container {
    height: 40rem;
    position: relative;
}

.galeria .swiper-slide {
    width: 37rem;
    height: 29rem;
    position: relative;
}

.galeria .swiper-slide img {
    width: 38rem;
    height: 28rem;
    border-radius: 2rem;
    object-fit: cover;
}

.galeria .slider-controler {
    position: relative;
    bottom: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.galeria .slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
}

@media (max-width: 768px) {
    .galeria .swiper-slide {
        width: 100% !important;
        height: auto !important;
    }

    .galeria .swiper-slide img {
        width: 100% !important;
        height: auto !important;
        border-radius: 0;
    }

    .galeria .swiper_container {
        height: auto;
    }

    .galeria .slider-controler {
        bottom: 6rem;
    }
}

@media (max-width: 500px) {
    .galeria .swiper_container {
        height: 47rem;
    }

    .galeria .swiper-slide {
        width: 28rem !important;
        height: 36rem !important;
    }

    .galeria .swiper-slide img {
        width: 28rem !important;
        height: 36rem !important;
    }

    .galeria .slider-controler {
        bottom: 3rem;
    }

    .galeria .slider-controler .swiper-button-next {
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}
