.fondo {
    filter: brightness(30%);
}

.swiper {
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.inicio .content {  
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin-left: 27%;
    padding: 0rem;
    z-index: 1;
}

.inicio .content p:nth-child(1) {
    font-size: 4rem;
    font-weight: 700;
    color: #fff;
}

.inicio .content p:nth-child(2) {
    font-size: 1.9rem;
    font-style: italic;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .inicio .content {
        margin-left: 10%;
    }

    .inicio .content p:nth-child(1) {
        font-size: 3rem;
    }

    .inicio .content p:nth-child(2) {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .inicio .content {
        margin-left: 5%;
    }

    .inicio .content p:nth-child(1) {
        font-size: 2.5rem;
    }

    .inicio .content p:nth-child(2) {
        font-size: 1.2rem;
    }
}
