/* Estilos base */
.contacto {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin-top: 2%;
    padding-top: 3%;
    background-color: #8d0505;
}

.form {
    width: 90%;
    max-width: 750px;
    height: auto;
    background: #000000;
    margin-left: 5%;
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0 0 40px -10px #000;
    padding: 10px 40px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    position: relative;
}

.form h1 {
    margin-top: 5%;
    padding-bottom: 20px;
    width: 100%;
    font-size: 1.6em;
    text-align: center;
    color: #ffffff;
    border-bottom: 3px solid #fc0000;
    border-radius: 20px;
}

.formcontainer p {
    color: #fff;
}

.formcontainer p:before {
    content: attr(type);
    display: block;
     margin: 40px 0 0;
    font-size: 14px;
}

.formcontainer input,
.formcontainer textarea {
    width: 100%;
    font-size: 0.9em;
    padding: 8px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    transition: all .3s;
    border-bottom: 2px solid #ffffff;
}

.formcontainer input:focus,
.formcontainer input:hover,
.formcontainer textarea:hover,
textarea:focus {
    border-bottom: 2px solid #ff0000;
}

.formcontainer button {
    float: right;
    margin: 0 1rem;
    display: block;
    width: fit-content;
    padding: 14px 16px;
    margin-top: 15px;
    font-size: 17px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #ff0000;
    background: #ff0000c2;
    color: #ffffff;
    cursor: pointer;
    border-radius: 8px;
}

.formcontainer button:hover {
    background-color: #750a0a;
    color: rgb(255, 255, 255);
    transition: all .2s;
}

.informacion {
    width: 90%;
    max-width: 400px;
    height: auto;
    background: #000000;
    margin-left: 5%;
    border-radius: 8px;
    box-shadow: 0 0 40px -10px #000;
    padding: 10px 30px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    position: relative;
}

.informacion .imglogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.informacion .imglogo img {
    margin-top: 3%;
    width: 40%;
}

.informacion h1 {
    color: #fff;
    font-size: 2.4em;
    text-align: center;
    margin-top: 2%;
    font-weight: bold;
}

.informacion .info {
    margin-top: 2%;
}

.informacion .info h3 {
    color: #fff;
    margin-top: 4%;
    font-size: 0.9em;
    font-weight: bold;
    position: relative;
}

.informacion .info p {
    color: #fff;
    font-size: 0.8em;
    margin-left: 1em;
}